import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { Power2 } from "gsap/all";
import { useRef } from "react";
import { useLocation } from "react-router-dom";


const Transition = () => {
    const divNegroRef = useRef(null)
    const divBlancoRef = useRef(null)
    const {pathname} = useLocation()

    useGSAP(() => {
        gsap.from(divNegroRef.current, {
            y: "-20%",
            duration: 2,
            ease: Power2.easeInOut
        })

        gsap.from(divBlancoRef.current, {
            y: "20%",
            stagger: 1,
            duration: 3,
            ease: Power2.easeInOut
        })
    }, {dependencies: [pathname]})

    return (
        <>
            <div ref={divNegroRef} className="transition transition--out" />
            <div ref={divBlancoRef} className="transition transition--in" />
        </>
    )
    
}


export default Transition;