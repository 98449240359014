import { configureStore } from "@reduxjs/toolkit";

import resourcesSlice from "./resources-slice";

const store = configureStore({
    reducer: {
        resources: resourcesSlice.reducer
    }
});

export default store;