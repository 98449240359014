import { useNavigate } from "react-router-dom";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";


const ProjectFooter = (props) => {
    const navigate = useNavigate();
    const footerRef = useRef(null)

    useGSAP(() => {
        gsap.from(footerRef.current, {
            width: 0,
            opacity: 0,
            ease: "power1.inOut",
            duration: 2,
            scrollTrigger: {
                // markers:true,
                trigger: footerRef.current,
                start: "top 90%",
                }
            }
        ) 
    })

    return (
        <footer className="project-footer" ref={footerRef}>
            <h6 className="project-footer__title">Menu</h6>
            <span className="project-footer__span"></span>

            <div className="project-footer__link-container">
                <div className="project-footer__link-list">
                    {/* <p className="project-footer__link">Next Project</p> */}
                    <p className="project-footer__link hoverable" onClick={()=> navigate(-1)}>Contact</p>
                    <p className="project-footer__link hoverable" onClick={()=> navigate(-1)}>Back </p>
                </div>
                
                <p className="project-footer__name">@Tomas Silva</p>
            </div>

             

        </footer>
    )
}


export default ProjectFooter;