// import { useNavigate } from "react-router-dom";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import Backarrow from "../../assets/Backarrow";
import { RedirectPage } from "../utils/utils";


const ContactFooter = (props) => {
    // const navigate = useNavigate();
    const footRef = useRef(null)

    useGSAP(() => {
        gsap.from(footRef.current, {
            width: 0,
            opacity: 0,
            ease: "power1.inOut",
            duration: 2,

            scrollTrigger: {
                // markers:true,
                trigger: footRef.current,
                start: "top bottom",
                end: "top 90%",
                }
            }
        ) 
    },
        { dependencies:[ footRef.current] })

    return (
        <footer className="contact-footer" ref={footRef}>
            <h6 className="contact-footer__title">Menu</h6>
            <span className="contact-footer__span"></span>

            <div className="contact-footer__link-container">
                <div className="contact-footer__link-list">
                    <div className="contact-footer__link hoverable" onClick={() => window.scrollTo(0, 0)}>
                        <Backarrow />
                    </div>
                    <div className="contact-footer__column">
                        <p className="contact-footer__link hoverable" onClick={() => RedirectPage("https://www.linkedin.com/in/tomas-silva-fiezzi-62aa181a4/")}>Linkedin</p>
                        <p className="contact-footer__link hoverable" onClick={() => RedirectPage("https://github.com/tsilva9")}>Github</p>
                        <p className="contact-footer__link hoverable" onClick={() => RedirectPage("mailto:tomas.sfiezzi@gmail.com")}>E-mail</p>
                    </div>
                </div>
                
                <p className="contact-footer__name">@Tomas Silva Fiezzi, 2024</p>
            </div>

             

        </footer>
    )
}


export default ContactFooter;