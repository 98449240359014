import { ReactLenis } from "@studio-freight/react-lenis";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect, useRef } from "react";

const SmoothScroll = ({children}) => {

    const lenisRef = useRef()

      useEffect(() => {
        function update(time) {
          lenisRef.current?.raf(time * 1000)
        }
        gsap.ticker.add(update)
        ScrollTrigger.update();
        return () => {
          gsap.ticker.remove(update)
        }
      })

    return (
        <ReactLenis root ref={lenisRef} autoRaf={false}
        options={{
            lerp: 0.7,
            duration: 1.5,
            // smoothTouch: true,
            // smoothWheel: true,
            orientation: "vertical",
            // gestureDirection: "horizontal"
        }}>
            {children}
        </ReactLenis>
    )
}

export default SmoothScroll