import gsap from "gsap";
import {useRef } from "react";
import {useGSAP} from "@gsap/react"
import ContactForm from "../components/ContactForm";
import ContactFooter from "../components/ContactFooter";
import Sol from "../../assets/Sol";

const Contact = (props) => {
    const pathAnimar = useRef();
    const solRef = useRef();
    const formRef = useRef();

    useGSAP(() => {
        gsap.to(pathAnimar.current, {
            attr: {d: pathAnimar.current.getAttribute("finalpath")},
            scrollTrigger: {
                // markers: true,
                trigger: pathAnimar.current,
                start: "top bottom",
                end: "top top",
                scrub: 3,
                ease: "power1.inOut",
            }
        })
    },  { dependencies:[ pathAnimar.current] })

    useGSAP(() => {
        gsap.from(formRef.current, {
            opacity: 0,
            ease: "power1.inOut",
            duration: 2,
            y: "50px",

            scrollTrigger: {
                // markers:true,
                trigger: formRef.current,
                start: "top bottom",
                end: "top 90%",
                }
            }
        ) 
    },{ dependencies:[ formRef.current] })

    return (
        <header className="contact">
            <svg viewBox={`0 0 1440 100`} fill="none" xmlns="http://www.w3.org/2000/svg" className="contact__shape">
                <path 
                    ref={pathAnimar}
                    d="M703.856 101C407.81 101 106.63 77.2067 0 65.7865V0H1440V60.0244C995.902 66.0595 1073.92 101 703.856 101Z"
                    finalpath="M711 40.8301C414.953 40.8301 270 110.677 0 90.3475V0H1440V150C985.5 119.083 1081.06 40.8301 711 40.8301Z"
                    fill="#242321"
                />
            </svg>
            <div className="contact__title">
                <h3 >Get in touch</h3>
            </div>
            <div className="contact__form-container" ref={formRef}><ContactForm /></div>
            <div className="contact__vector"><Sol ref={solRef}/></div>
            <div className="contact__footer-container"><ContactFooter containerAnimation={props.containerAnimation}/></div>
        </header>
    )
}

export default Contact;