import { useEffect, useState } from "react";
import ProjectDescription from "../components/ProjectDescription";
import ProjectFooter from "../components/ProjectFooter";
import ProjectHeader from "../components/ProjectHeader";
import TransitionOut from "../components/Transition";
import LoadingScreen from "./LoadingScreen";

const ProjectPage = (props) => {
    const [videoReady, setVideoReady] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleVideoLoad = () => {
        setVideoReady(true);
        
      };

    return (
        <>
       {!videoReady ? <LoadingScreen /> : <TransitionOut />}
        <article className="project-page">
            <ProjectHeader video={props.video} handleVideoLoad={handleVideoLoad} title={props.data.name} link={props.data.external}/>
            <ProjectDescription text={props.data.text}/>
            <ProjectFooter />
        </article>
        </>
    )
}

export default ProjectPage;