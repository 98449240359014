import { useRef} from "react";
import ProjectThumb from "../components/ProjectThumb";
import {useGSAP} from "@gsap/react"
import gsap from "gsap";
import useScreenWidth from "../utils/useScreenWidth";

const ProjectGallery = (props) => {
    const containerRef = useRef(null)
    let isSmallScreen = useScreenWidth(600);

    useGSAP(() => {
        const container = containerRef.current;
        if (!container) return;

        if (!isSmallScreen) {
        container.childNodes.forEach(box => {
            gsap.fromTo(box, 
                {y: `${-100 * (box.id % 2 === 0 ? -1 : 1)}px`},
                {
                    y:  `${50 * (box.id % 2 === 0 ? -1 : 1)}px`,
                    ease: "none",
                    scrollTrigger: {
                        // markers: true,
                        start: "left right",
                        trigger: box,
                        containerAnimation: props.containerAnimation.current,
                        scrub: 3,
                        end: "right left"
                    }
              });

              gsap.fromTo(box.childNodes[0], 
                {y: `${50 * (box.id % 2 === 0 ? -1 : 1)}px`},
                {
                    y:  `${-40 * (box.id % 2 === 0 ? -1 : 1)}px`,
                    ease: "none",
                    scrollTrigger: {
                        // markers: true,
                        start: "left right",
                        trigger: box.childNodes[0],
                        containerAnimation: props.containerAnimation.current,
                        scrub: 1,
                        end: "right left"
                    }});
        })} else {
            container.childNodes.forEach(box => {
                gsap.fromTo(box, 
                    {x: `${-30 * (box.id % 2 === 0 ? -1 : 1)}px`},
                    {
                        x:  `${15 * (box.id % 2 === 0 ? -1 : 1)}px`,
                        ease: "none",
                        scrollTrigger: {
                            // markers: true,
                            start: "top bottom",
                            trigger: box,
                            containerAnimation: props.containerAnimation.current,
                            scrub: 3,
                            end: "bottom top"
                        }
                  });
    
                  gsap.fromTo(box.childNodes[0], 
                    {x: `${10 * (box.id % 2 === 0 ? -1 : 1)}px`},
                    {
                        x:  `${-10 * (box.id % 2 === 0 ? -1 : 1)}px`,
                        ease: "none",
                        scrollTrigger: {
                            // markers: true,
                            start: "top bottom",
                            trigger: box.childNodes[0],
                            containerAnimation: props.containerAnimation.current,
                            scrub: 1,
                            end: "bottom top"
                        }});
            })


        }


        },{ dependencies:[ props.containerAnimation.current] }
        );

    return (
        <section className="project-gallery elemento-horizontal" ref={containerRef}>
            <ProjectThumb id="1" nombre="CienciaConecta" img={require("../../assets/capturas/cienciaconecta.jpg")}/>
            <ProjectThumb id="2" nombre="Pap Detect"  img={require("../../assets/capturas/pap.jpg")}/>
            <ProjectThumb id="3" nombre="Information Retrieval" img={require("../../assets/capturas/informationret.jpg")}/>
            <ProjectThumb id="4" nombre="Ezequiel Torres" img={require("../../assets/capturas/ezetorres.jpg")}/>
            <ProjectThumb id="5" nombre="Natours" img={require("../../assets/capturas/natours.jpg")}/>
        </section>
    )
}

export default ProjectGallery;