import React from "react";

const Sol = React.forwardRef((props, ref) => {
    return (
<svg version="1.2" baseProfile="tiny" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
	 x="0px" y="0px"  viewBox="0 0 345 345"  xmlSpace="preserve" fill="none"  ref={ref}>
    <path d="M172.457 242.374C210.841 242.374 241.957 211.258 241.957 172.874C241.957 134.49 210.841 103.374 172.457 103.374C134.074 103.374 102.957 134.49 102.957 172.874C102.957 211.258 134.074 242.374 172.457 242.374Z" stroke="#E5E5E0"/>
    <path d="M174.193 1.66395C173.693 0.970102 171.693 0.970107 171.193 1.66384C170.693 2.35758 166.693 103.144 166.693 103.144L178.693 103.144C178.693 103.144 174.693 2.35779 174.193 1.66395Z" stroke="#E5E5E0"/>
    <path d="M184.741 79.3331C184.26 88.6244 182.634 99.5082 181.882 103.789L193.337 106.62C195.199 99.3551 194.316 80.2378 195.715 73.1638C197.464 64.3213 201.318 60.5601 203.021 55.5252C204.724 50.4903 207.351 45.0727 206.025 35.3391C204.699 25.6056 203.327 19.6795 204.297 15.4893C205.074 12.1372 212.095 5.57014 210.125 6.03986C206.02 7.01846 197.99 11.4991 198.707 21.5929C199.604 34.2102 198.429 44.4386 197.076 47.705C195.723 50.9714 185.342 67.7189 184.741 79.3331Z" stroke="#E5E5E0"/>
    <path d="M171.07 344.017C171.57 344.711 173.57 344.711 174.07 344.017C174.57 343.323 178.57 242.537 178.57 242.537L166.57 242.537C166.57 242.537 170.57 343.323 171.07 344.017Z" stroke="#E5E5E0"/>
    <path d="M160.523 266.348C161.004 257.056 162.629 246.172 163.382 241.892L151.926 239.06C150.065 246.326 150.948 265.443 149.549 272.517C147.799 281.359 143.945 285.121 142.242 290.155C140.54 295.19 137.913 300.608 139.239 310.342C140.564 320.075 141.937 326.001 140.966 330.191C140.19 333.544 133.169 340.111 135.139 339.641C139.243 338.662 147.273 334.182 146.556 324.088C145.659 311.47 146.835 301.242 148.188 297.976C149.541 294.709 159.922 277.962 160.523 266.348Z" stroke="#E5E5E0"/>
    <path d="M1.45539 171.279C0.761545 171.779 0.76155 173.779 1.45529 174.279C2.14902 174.779 102.935 178.779 102.935 178.779L102.935 166.779C102.935 166.779 2.14924 170.779 1.45539 171.279Z" stroke="#E5E5E0"/>
    <path d="M79.1245 160.732C88.4158 161.213 99.2996 162.838 103.58 163.591L106.412 152.135C99.1465 150.274 80.0292 151.157 72.9552 149.757C64.1127 148.008 60.3515 144.154 55.3166 142.451C50.2817 140.748 44.8641 138.122 35.1306 139.447C25.397 140.773 19.471 142.145 15.2808 141.175C11.9286 140.399 5.36158 133.377 5.83131 135.348C6.8099 139.452 11.2905 147.482 21.3844 146.765C34.0017 145.868 44.23 147.043 47.4964 148.396C50.7628 149.749 67.5104 160.131 79.1245 160.732Z" stroke="#E5E5E0"/>
    <path d="M343.808 174.401C344.502 173.901 344.502 171.901 343.808 171.401C343.115 170.901 242.329 166.901 242.329 166.901L242.329 178.901C242.329 178.901 343.114 174.901 343.808 174.401Z" stroke="#E5E5E0"/>
    <path d="M266.139 184.949C256.848 184.468 245.964 182.843 241.683 182.09L238.852 193.546C246.117 195.407 265.234 194.524 272.308 195.923C281.151 197.673 284.912 201.527 289.947 203.23C294.982 204.932 300.399 207.559 310.133 206.233C319.867 204.908 325.793 203.535 329.983 204.506C333.335 205.282 339.902 212.303 339.432 210.333C338.454 206.228 333.973 198.199 323.879 198.916C311.262 199.813 301.034 198.637 297.767 197.284C294.501 195.931 277.753 185.55 266.139 184.949Z" stroke="#E5E5E0"/>
    <path d="M52.6957 50.6965C51.8516 50.5594 50.4374 51.9737 50.5744 52.8178C50.7113 53.6619 119.149 127.757 119.149 127.757L127.635 119.271C127.635 119.271 53.5399 50.8336 52.6957 50.6965Z" stroke="#E5E5E0"/>
    <path d="M115.074 98.1586C121.304 105.069 127.851 113.914 130.346 117.473L140.448 111.375C136.627 104.921 122.485 92.0278 118.472 86.0361C113.456 78.5466 113.522 73.1618 111.166 68.3975C108.81 63.6332 106.836 57.945 99.0163 51.9998C91.1961 46.0547 86.0354 42.8346 83.7587 39.1856C81.9372 36.2663 82.2585 26.6579 81.1975 28.3832C78.9871 31.9776 76.4774 40.8239 84.122 47.454C93.6778 55.7417 100.079 63.8053 101.432 67.0717C102.785 70.3381 107.287 89.521 115.074 98.1586Z" stroke="#E5E5E0"/>
    <path d="M292.568 294.984C293.412 295.121 294.826 293.707 294.689 292.863C294.552 292.019 226.114 217.924 226.114 217.924L217.629 226.409C217.629 226.409 291.724 294.847 292.568 294.984Z" stroke="#E5E5E0"/>
    <path d="M230.189 247.522C223.959 240.612 217.413 231.767 214.918 228.208L204.815 234.306C208.636 240.759 222.779 253.653 226.791 259.645C231.807 267.134 231.741 272.519 234.098 277.283C236.454 282.047 238.427 287.736 246.247 293.681C254.067 299.626 259.228 302.846 261.505 306.495C263.326 309.414 263.005 319.023 264.066 317.297C266.276 313.703 268.786 304.857 261.141 298.227C251.586 289.939 245.184 281.875 243.831 278.609C242.478 275.343 237.977 256.16 230.189 247.522Z" stroke="#E5E5E0"/>
    <path d="M50.488 292.776C50.3509 293.62 51.7651 295.034 52.6092 294.897C53.4533 294.76 127.548 226.322 127.548 226.322L119.063 217.837C119.063 217.837 50.625 291.932 50.488 292.776Z" stroke="#E5E5E0"/>
    <path d="M97.9501 230.398C104.86 224.168 113.705 217.621 117.264 215.126L111.166 205.024C104.713 208.845 91.8192 222.987 85.8276 227C78.3381 232.015 72.9533 231.95 68.189 234.306C63.4247 236.662 57.7365 238.635 51.7913 246.456C45.8461 254.276 42.6261 259.436 38.9771 261.713C36.0578 263.535 26.4494 263.213 28.1747 264.274C31.769 266.485 40.6153 268.995 47.2455 261.35C55.5332 251.794 63.5968 245.393 66.8632 244.04C70.1296 242.687 89.3125 238.185 97.9501 230.398Z" stroke="#E5E5E0"/>
    <path d="M294.776 52.9037C294.913 52.0595 293.498 50.6453 292.654 50.7823C291.81 50.9193 217.715 119.357 217.715 119.357L226.201 127.843C226.201 127.843 294.638 53.7479 294.776 52.9037Z" stroke="#E5E5E0"/>
    <path d="M247.313 115.283C240.403 121.513 231.558 128.06 227.999 130.554L234.097 140.657C240.551 136.836 253.444 122.693 259.436 118.681C266.925 113.665 272.31 113.731 277.075 111.375C281.839 109.019 287.527 107.045 293.472 99.225C299.417 91.4049 302.637 86.2442 306.286 83.9674C309.206 82.146 318.814 82.4673 317.089 81.4063C313.494 79.1958 304.648 76.6861 298.018 84.3308C289.73 93.8866 281.667 100.288 278.4 101.641C275.134 102.994 255.951 107.496 247.313 115.283Z" stroke="#E5E5E0"/>
    <path d="M108.568 14.097C107.84 13.6473 105.993 14.4127 105.796 15.2449C105.6 16.0772 140.473 110.722 140.473 110.722L151.56 106.13C151.56 106.13 109.295 14.5467 108.568 14.097Z" stroke="#E5E5E0"/>
    <path d="M148.035 81.8168C151.146 90.585 153.81 101.262 154.753 105.505L166.42 103.737C165.359 96.3126 157.228 78.9885 155.813 71.9174C154.046 63.0786 156.167 58.1288 155.813 52.8255C155.46 47.5222 155.813 41.5118 150.864 33.0265C145.914 24.5413 142.378 19.5915 141.671 15.3489C141.106 11.9548 145.079 3.20072 143.439 4.38866C140.021 6.86353 134.317 14.076 138.843 23.127C144.5 34.4407 147.328 44.3402 147.328 47.8757C147.328 51.4113 144.146 70.8567 148.035 81.8168Z" stroke="#E5E5E0"/>
    <path d="M236.696 331.585C237.423 332.034 239.271 331.269 239.467 330.437C239.664 329.604 204.79 234.96 204.79 234.96L193.704 239.552C193.704 239.552 235.968 331.135 236.696 331.585Z" stroke="#E5E5E0"/>
    <path d="M197.228 263.864C194.117 255.096 191.454 244.418 190.511 240.176L178.844 241.943C179.904 249.368 188.036 266.692 189.45 273.763C191.218 282.602 189.097 287.552 189.45 292.855C189.804 298.158 189.45 304.169 194.4 312.654C199.35 321.139 202.885 326.089 203.592 330.332C204.158 333.726 200.184 342.48 201.825 341.292C205.242 338.817 210.946 331.605 206.421 322.554C200.764 311.24 197.936 301.34 197.936 297.805C197.936 294.269 201.117 274.824 197.228 263.864Z" stroke="#E5E5E0"/>
    <path d="M13.888 236.905C13.4383 237.632 14.2037 239.48 15.036 239.677C15.8683 239.873 110.513 204.999 110.513 204.999L105.921 193.913C105.921 193.913 14.3377 236.177 13.888 236.905Z" stroke="#E5E5E0"/>
    <path d="M81.6086 197.437C90.3767 194.326 101.054 191.662 105.297 190.72L103.529 179.052C96.1043 180.113 78.7802 188.245 71.7091 189.659C62.8703 191.427 57.9206 189.305 52.6173 189.659C47.314 190.013 41.3035 189.659 32.8183 194.609C24.333 199.558 19.3832 203.094 15.1406 203.801C11.7465 204.367 2.99244 200.393 4.18038 202.033C6.65525 205.451 13.8677 211.155 22.9187 206.63C34.2324 200.973 44.1319 198.144 47.6674 198.144C51.203 198.144 70.6484 201.326 81.6086 197.437Z" stroke="#E5E5E0"/>
    <path d="M331.375 108.776C331.825 108.048 331.06 106.2 330.228 106.004C329.395 105.808 234.75 140.681 234.75 140.681L239.343 151.768C239.343 151.768 330.926 109.503 331.375 108.776Z" stroke="#E5E5E0"/>
    <path d="M263.655 148.243C254.887 151.355 244.21 154.018 239.967 154.961L241.735 166.628C249.159 165.568 266.483 157.436 273.554 156.022C282.393 154.254 287.343 156.375 292.646 156.022C297.95 155.668 303.96 156.022 312.445 151.072C320.931 146.122 325.88 142.587 330.123 141.88C333.517 141.314 342.271 145.288 341.083 143.647C338.608 140.23 331.396 134.526 322.345 139.051C311.031 144.708 301.132 147.536 297.596 147.536C294.061 147.536 274.615 144.354 263.655 148.243Z" stroke="#E5E5E0"/>
    <path d="M15.0829 105.892C14.2505 106.089 13.4852 107.936 13.9348 108.664C14.3843 109.391 105.968 151.656 105.968 151.656L110.56 140.569C110.56 140.569 15.9153 105.696 15.0829 105.892Z" stroke="#E5E5E0"/>
    <path d="M90.8762 125.87C99.2761 129.87 108.709 135.537 112.376 137.87L119.376 128.37C113.376 123.87 95.3762 117.37 89.3762 113.37C81.8762 108.37 79.8762 103.37 75.8762 99.8701C71.8762 96.3701 67.8762 91.8701 58.3762 89.3701C48.8762 86.8701 42.8762 85.8701 39.3762 83.3701C36.5763 81.3701 33.1962 72.3701 32.8762 74.3701C32.2095 78.5368 33.2762 87.6701 42.8762 90.8701C54.8762 94.8701 63.8762 99.8701 66.3762 102.37C68.8762 104.87 80.3762 120.87 90.8762 125.87Z" stroke="#E5E5E0"/>
    <path d="M330.181 239.789C331.013 239.592 331.778 237.744 331.329 237.017C330.879 236.29 239.296 194.025 239.296 194.025L234.704 205.111C234.704 205.111 329.348 239.985 330.181 239.789Z" stroke="#E5E5E0"/>
    <path d="M254.387 219.811C245.987 215.811 236.554 210.144 232.887 207.811L225.887 217.311C231.887 221.811 249.887 228.311 255.887 232.311C263.387 237.311 265.387 242.311 269.387 245.811C273.387 249.311 277.387 253.811 286.887 256.311C296.387 258.811 302.387 259.811 305.887 262.311C308.687 264.311 312.067 273.311 312.387 271.311C313.054 267.144 311.987 258.011 302.387 254.811C290.387 250.811 281.387 245.811 278.887 243.311C276.387 240.811 264.887 224.811 254.387 219.811Z" stroke="#E5E5E0"/>
    <path d="M105.683 330.389C105.879 331.222 107.727 331.987 108.455 331.537C109.182 331.088 151.447 239.505 151.447 239.505L140.36 234.912C140.36 234.912 105.487 329.557 105.683 330.389Z" stroke="#E5E5E0"/>
    <path d="M125.661 254.596C129.661 246.196 135.328 236.762 137.661 233.096L128.161 226.096C123.661 232.096 117.161 250.096 113.161 256.096C108.161 263.596 103.161 265.596 99.6611 269.596C96.1611 273.596 91.6611 277.596 89.1611 287.096C86.6611 296.596 85.6611 302.596 83.1611 306.096C81.1611 308.896 72.1611 312.276 74.1611 312.596C78.3277 313.262 87.4611 312.196 90.6611 302.596C94.6611 290.596 99.6611 281.596 102.161 279.096C104.661 276.596 120.661 265.096 125.661 254.596Z" stroke="#E5E5E0"/>
    <path d="M239.58 15.2913C239.384 14.459 237.536 13.6936 236.809 14.1432C236.081 14.5928 193.816 106.176 193.816 106.176L204.903 110.768C204.903 110.768 239.777 16.1237 239.58 15.2913Z" stroke="#E5E5E0"/>
    <path d="M219.602 91.0849C215.602 99.4849 209.936 108.918 207.602 112.585L217.102 119.585C221.602 113.585 228.102 95.585 232.102 89.585C237.102 82.085 242.102 80.085 245.602 76.085C249.102 72.085 253.602 68.085 256.102 58.585C258.602 49.085 259.602 43.0849 262.102 39.585C264.102 36.785 273.102 33.4049 271.102 33.0849C266.936 32.4182 257.802 33.4849 254.602 43.0849C250.602 55.0849 245.602 64.0849 243.102 66.5849C240.602 69.0849 224.602 80.5849 219.602 91.0849Z" stroke="#E5E5E0"/>
</svg>
    )
})

export default Sol;