import React from "react";

const Mate = React.forwardRef((props, ref) => {
    return (
        <svg width="186" height="394" viewBox="0 0 186 394" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref}>
            <path d="M56.9759 121.624C67.5979 120.211 80.7059 119.348 94.0399 119.348C132.611 119.348 163.949 126.256 163.949 134.812C163.949 143.369 132.686 150.277 94.0399 150.277C55.3939 150.277 24.1306 143.369 24.1306 134.812C24.1306 130.338 33.1706 126.256 46.7306 123.43" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M54.6406 110.085C66.5433 108.279 80.706 107.259 94.8686 107.259C144.438 107.259 184.591 119.426 184.591 134.42C184.591 149.413 144.438 161.581 94.8686 161.581C45.2993 161.581 5.07129 149.413 5.07129 134.42C5.07129 125.157 21.042 116.836 44.3953 111.89" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M5.07129 134.42L5.14696 143.997" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M184.591 134.42V143.84" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M184.591 143.526C184.591 158.52 144.438 170.687 94.8687 170.687C45.2994 170.687 5.14673 158.52 5.14673 143.526" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M5.67407 146.509C17.2754 175.475 19.6861 184.11 18.7067 199.653" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M18.7068 199.575C18.7068 218.572 9.36542 221.869 7.93408 244.163" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M67.6733 349.431C32.7186 334.987 8.00928 293.304 8.00928 244.241M94.8686 354.769C88.5406 354.769 81.7606 353.749 75.8846 352.1" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M184.063 146.588C172.462 175.554 169.825 184.111 170.805 199.575" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M73.0973 294.796C69.4813 313.322 65.5639 377.613 67.4473 392.371" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M81.7607 295.267C78.446 315.049 72.8713 377.927 74.83 392.764" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M66.9954 392.764C66.9954 392.764 70.6867 392.764 75.2067 392.842" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M74.8301 392.764C83.7194 373.61 85.6781 370.47 90.3488 354.77" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M170.729 199.575C170.729 218.572 180.071 221.869 181.502 244.163" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M181.502 244.163C181.502 305.315 143.308 354.77 94.5674 354.77" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M67.6733 349.981C55.6953 349.667 26.0893 344.486 13.5087 343.309" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M13.81 343.544C11.098 318.738 11.6253 309.397 11.6253 276.191" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M97.1287 354.77C114.832 360.422 139.541 360.108 171.407 363.483" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M170.955 363.483C176.078 321.014 172.613 293.068 173.291 291.42" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M50.3467 146.666C45.2993 106.631 37.766 86.7703 32.0406 76.8008" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M61.044 148.236C55.9967 108.201 46.58 75.8593 42.3613 65.6543" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M27.9727 71.6201C31.89 76.4871 35.732 82.7671 42.512 85.2791" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M42.1353 64.7119C43.8679 72.1694 43.6419 76.8009 42.1353 85.4359" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M28.1234 71.8554C13.6594 38.1004 5.14669 16.7484 0.702026 2.77539" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M42.512 66.2037C33.5473 32.0562 24.9593 16.6702 18.9326 2.30469" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M18.9326 2.30484C18.8573 3.32534 14.714 4.26734 9.66664 4.42434C4.61931 4.58134 0.626643 3.79634 0.701976 2.77584C0.77731 1.75534 4.92063 0.813338 9.96797 0.656338C14.94 0.577838 19.008 1.28434 18.9326 2.30484Z" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M56.976 121.703L126.961 148.393" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M77.1653 119.74L144.664 145.41" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M96.978 119.348L155.286 142.191" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M116.565 120.133L162.593 137.717" stroke="#E5E5E0" strokeMiterlimit="10"/>
        </svg>
    )
})

export default Mate;