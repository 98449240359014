const Works = () => {
    return (
        <section className="works elemento-horizontal">
            <div className="works__intro">
                <h3 className="works__title">Selected Works</h3>
                <p className="works__text">
                
                Engineering, for me, is the perfect framework, 
                but problems often have thousands of different possible solutions. 
                Design is about exploring the space of solutions and finding a compromise between the variables at play. 
                While studying, I tried to stay up to date with various technologies and completed various projects 
                for both university, work, or personal purposes. This is a humble list of them.

                </p>
            </div>

            {/* <ProjectThumb/>
            <ProjectThumb/> */}
            
        </section>
    )
}

export default Works;