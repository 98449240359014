import { useGSAP } from "@gsap/react";
import { useRef } from "react";

import gsap from "gsap";

const ProjectDescription = (props) => {

    const titleRef = useRef(null)
    const textRef = useRef(null)

    useGSAP(() => {
        gsap.from(titleRef.current, {
            width: 0,
            opacity: 0,
            ease: "expo.out",
            duration: 3,
            scrollTrigger: {
                // markers:true,
                trigger: titleRef.current,
                }
            }
        ) 

        gsap.from(textRef.current, {
            opacity: 0,
            y: "100px",
            ease: "expo.out",
            duration: 5,
            scrollTrigger: {
                // markers:true,
                trigger: textRef.current,
                start: "top 75%",
                }
            }
        ) 
    })


    return (
        <section className="project-description">
            <div className="project-description__title-container" ref={titleRef}> 
                <div className="project-description__text-number">
                    <h6 className="project-description__text project-description__text--project">The project</h6>
                    <p className="project-description__text project-description__text--number">01</p>
                </div>
                
                <span className="project-description__span"> </span>
            </div>

            <div className="project-description__text-container" ref={textRef}>
            {props.text}
            </div>
        </section>
    )
}

export default ProjectDescription;