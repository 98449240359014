import { useCallback, useEffect, useMemo, useState } from "react";


const CustomCursor = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [hoveredElement, setHoveredElement] = useState(null);

    const handleMouseMove = useCallback(
    (e) => {
        setPosition({ x: e.clientX, y: e.clientY });
        setHoveredElement(document.elementFromPoint(e.clientX, e.clientY) ? document.elementFromPoint(e.clientX, e.clientY) : null)
    }, []);

    const cursorClass = useMemo(() => {
        let depth = 0;
        let parentElement = hoveredElement;

        if (hoveredElement) {

            while (parentElement && !parentElement.classList.contains("hoverable") && depth < 4) {
                parentElement = parentElement.parentElement
                depth++;
            }
            
            return parentElement ? parentElement.classList.contains("hoverable") ? "custom-cursor--hover" : "" : ""
            
        }
        
    }, [hoveredElement])

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        };
    });


    return (
        <div className={`custom-cursor ${cursorClass}`} style={{ left: `${position.x}px`, top: `${position.y}px` }} />
    )
}

export default CustomCursor;