import { useRef, useState } from "react"
import * as emailjs from "@emailjs/browser"

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ContactForm = (props) => {
    const formRef = useRef(null)
    const [isSent, setIsSent] = useState(false)

    const [formValues, setFormValues] = useState({
        user_name: "",
        user_email: "",
        message: ""
      })
      const [errors, setErrors] = useState({
        user_name: "",
        user_email: "",
        message: ""
      })

      const handleChange = (e) => {
        const { name, value } = e.target;
        let errorMessage = "";
      
        if (name === 'user_name') {
          errorMessage = value.length === 0 ? "Please enter your name." :
            value.length > 30 ? "The name should have less than 30 characters." : "";
        }
      
        if (name === 'user_email') {
          errorMessage = value.length === 0 ? "Please enter your email address." :
            value.length > 40 ? "The email address should have less than 40 characters." :
            !emailRegex.test(value) ? "Please enter a valid email address." : "";
        }
      
        if (name === 'message') {
          errorMessage = value.length === 0 ? "Please enter a message." :
            value.length > 200 ? "Message is too long." : "";
        }
      
        const nextErrors = { ...errors, [name]: errorMessage };
        const nextFormValueState = { ...formValues, [name]: value };
      
        setErrors(nextErrors);
        setFormValues(nextFormValueState);
      }

      const handleBlur = (e) => {
        let {name, value} = e.target
        let nextErrors = errors

        if (name === 'user_name' && value === ""){
          nextErrors = {...nextErrors, [name]: "Please enter your name."}
        }

        if (name === 'user_email' && value === ""){
            nextErrors = {...nextErrors, [name]: "Please enter your email address."}
          }

          if (name === 'message' && value === ""){
            nextErrors = {...nextErrors, [name]: "Please enter a message."}
          }
        setErrors(nextErrors)
      }

      const handleSubmit = (e) => {
        e.preventDefault()
        setIsSent(true)

        emailjs.sendForm("service_jt45rlu", 'template_2exvjgc', formRef.current)
            .then(() => {
            }, (error) => {
                console.log();
            });
    }

    return (
        <form className="contact-form" ref={formRef} onSubmit={handleSubmit} id="contact-form">

            <div className="contact-form__input-container">
                <label className="contact-form__label" htmlFor="">Full Name</label>
                <input className="contact-form__input hoverable" type="text" onBlur={handleBlur} onChange={handleChange} name="user_name" required={true} value={formValues.user_name}/>
                {errors.user_name ? <small className="contact-form__error">{"* " + errors.user_name}</small> : ""}
            </div>

            <div className="contact-form__input-container">
                <label className="contact-form__label" htmlFor="">E-mail</label>
                <input className="contact-form__input hoverable" type="text" onBlur={handleBlur} onChange={handleChange} name="user_email" required={true} value={formValues.user_email}/>
                {errors.user_email ? <small className="contact-form__error">{"* " + errors.user_email}</small> : ""}
            </div>

            <div className="contact-form__input-container">
                <label className="contact-form__label" htmlFor="">Message</label>
                <textarea className="contact-form__input hoverable" rows={4} type="textarea" onBlur={handleBlur} onChange={handleChange} name="message" required={true} value={formValues.message}/>
                {errors.message ? <small className="contact-form__error">{"* " + errors.message}</small> : ""}
            </div>

            <div className="contact-form__button-container hoverable">
                <button className={!isSent ? "contact-form__button": "contact-form__button--disabled"} disabled={isSent}>{isSent ? "Sent!" : "Send"}</button>
            </div>

        </form>
    )
}

export default ContactForm;