import { useEffect, useState } from "react";
import Main from "./js/layout/Main"
import { useSelector } from "react-redux";
import LoadingScreen from "./js/layout/LoadingScreen";
import TransitionOut from "./js/components/Transition";

let firstRender = true;

const App = () => {
  const loadedImages = useSelector(state => state.resources.loadedImages)
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    if (loadedImages === 5 || !firstRender) {
      firstRender = false;
      window.scrollTo(0, 0);
      setIsLoading(false)
    }
  }, [loadedImages])

  return (
    <>
      
      {isLoading ? <LoadingScreen progress={(loadedImages/5) * 100 + "%"}/> : <TransitionOut />}
        
      <div className={isLoading ? "app--loading" : ""}>
        
        <Main />
      </div>
    </>

  )


}

export default App;