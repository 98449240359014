import React from "react";


const Sierra = React.forwardRef((props, ref) => {
    return (
        <svg width="606" height="242" viewBox="0 0 606 242" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref}>
            <path d="M91.934 236.651C120.539 228.131 156.084 211.404 177.091 199.731C184.25 195.755 193.796 183.657 197.878 178.147C201.551 173.177 207.486 174.825 212.51 170.763C246.108 143.727 262.216 106.948 305.831 88.9713C321.845 82.3825 333.777 65.6833 338.079 64.7461C344.83 63.2693 354.312 55.3457 361.252 55.3457" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M360.153 55.402C365.742 55.5156 370.986 52.9028 375.131 49.6368C379.275 46.3708 382.698 42.3948 386.749 38.9868C392.746 33.96 400.031 30.2964 407.221 26.6896C410.33 25.156 413.596 23.5656 417.144 23.594C419.499 23.6224 421.791 24.3892 424.115 24.3608C429.924 24.304 434.445 19.5896 440.129 18.624C443.112 18.1128 446.252 18.6808 449.14 19.618C461.983 23.7644 470.492 34.244 480.823 42.1392C494.137 52.3916 511.03 58.6396 523.81 69.4032C528.551 73.3792 532.602 77.8948 536.684 82.4104C545.005 91.6404 553.514 101.183 557.188 112.486C558.067 115.212 558.695 118.052 559.857 120.694C562.243 126.061 566.859 130.35 570.941 134.837C579.953 144.777 584.6 154.717 588.713 166.929" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M115.453 228.529C128.17 220.236 131.592 209.103 135.517 196.039C139.442 182.975 141.891 169.315 149.71 157.557C158.879 143.812 174.987 133.303 180.419 118.251C182.178 113.395 182.743 108.255 185.066 103.569C190.404 92.7483 204.001 86.4435 217.314 82.7231C230.628 79.0027 244.883 76.9011 256.753 70.6531C261.682 68.0403 266.173 64.7459 271.636 62.9567C278.638 60.6563 286.457 61.0539 293.962 61.5083C309.41 62.4455 324.263 63.3543 339.743 64.2631" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M1.09375 219.412C26.2765 202.315 45.8702 176.471 56.1066 146.992C58.2732 140.744 60.1258 134.155 64.2706 129.1C73.8476 117.513 91.7769 118.28 103.081 108.482C115.17 97.9735 116.112 78.6899 125.877 65.7679C133.539 55.5723 145.722 50.5171 157.309 45.9163C163.306 43.5307 169.366 41.1167 175.709 40.0375C188.269 37.9075 201.206 41.1451 213.829 39.2423C223.751 37.7371 232.983 33.1079 242.748 30.7791C251.477 28.7059 260.458 28.4787 269.375 28.3651C283.348 28.1379 297.824 28.1947 310.635 34.1303C316.601 36.8851 321.939 40.8327 327.277 44.7235C335.284 50.6023 340.308 54.3227 348.315 60.2015" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M604.979 186.383C599.986 179.084 594.868 171.643 587.552 166.048C580.078 160.34 570.815 156.904 562.589 152.161C547.642 143.612 536.244 131.031 526.165 118.081C522.554 113.452 518.409 108.34 512.129 107.346C501.296 105.642 490.683 117.286 480.792 113.026C477.652 111.662 475.454 108.908 472.125 107.857C466.976 106.238 461.606 109.39 457.556 112.6C449.203 119.188 441.981 126.771 434.759 134.326C432.624 136.569 430.457 138.813 428.322 141.056C422.325 147.333 416.296 153.609 410.299 159.914C407.033 163.35 403.736 166.787 400.879 170.507C397.016 175.506 394.033 180.987 389.857 185.758C384.08 192.375 376.261 197.402 368.599 202.315C354.972 211.034 341.313 219.781 327.151 227.762C315.816 234.152 303.696 240.172 290.288 241.479" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M435.795 133.247C418.934 142.988 402.103 143.301 382.101 139.495C374.597 138.075 367.061 136.428 359.336 136.627C353.936 136.769 348.629 137.819 343.385 138.927C331.453 141.483 319.71 144.437 308.092 147.845C300.367 150.088 292.737 152.531 285.107 154.973C275.436 158.069 265.67 161.193 257.286 166.276C253.675 168.463 250.378 171.019 246.516 172.922C237.222 177.551 224.882 178.545 218.288 185.702C216.247 187.917 214.959 190.558 212.667 192.603C210.186 194.818 206.732 196.125 203.404 197.346C186.95 203.31 169.115 207.825 152.065 212.597" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M294.715 29.5013L294.464 0.504883" stroke="#E5E5E0" strokeMiterlimit="10"/>
            <path d="M284.29 8.74121H304.889" stroke="#E5E5E0" strokeMiterlimit="10"/>
        </svg>


    )
})

export default Sierra;