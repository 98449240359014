import gsap from "gsap";
import {useRef } from "react";
import {useGSAP} from "@gsap/react"


const Hero = () => {
    const pathAnimar = useRef();

    useGSAP(() => {
        gsap.to(pathAnimar.current, {
            attr: {d: pathAnimar.current.getAttribute("finalpath")},
            scrollTrigger: {
                trigger: pathAnimar.current,
                start: "bottom bottom",
                end: "bottom top",
                scrub: 3,
                ease: "power1.inOut",
            }
        })
    })

    return (
        <header className="hero">
            <div className="hero__title">
                <h1 >Tomas Silva Fiezzi</h1>
                <h2 >software engineer</h2>
            </div>
            
            <svg viewBox={`0 0 1440 200`} fill="none" xmlns="http://www.w3.org/2000/svg" className="hero__shape">
                <path 
                    ref={pathAnimar}
                    d="M703.856 0C407.81 0 106.63 61.0146 0 90.3V259H1440V105.076C995.902 89.6 1073.92 0 703.856 0Z"
                    finalpath="M711 188.5C414.953 188.5 270 67.8978 0 103V259H1440V0C985.5 53.3842 1081.06 188.5 711 188.5Z"
                    fill="#242321"
                />
            </svg>
        </header>
    )
}

export default Hero;
