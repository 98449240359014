const AboutMe = () => {
    
    return (
        <section className="about-me elemento-horizontal" >
            <div className="about-me__main">
                <h3 className="about-me__title">About Me</h3>
                <p className="about-me__text">
                
                Since I was a kid, I've been interested in computers. I turned that passion into my 
                career when I entered university to study Information Systems Engineering. One of my favorite things 
                is coming up with creative solutions leveraging my technical knowledge to provide users with a meaningful experience.

                </p>
            </div>
        </section>
    )
}

export default AboutMe;