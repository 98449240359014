import React from 'react';
import ReactDOM from 'react-dom/client';
import "./css/style.css"
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProjectPage from './js/layout/ProjectPage';
import CustomCursor from './js/components/CustomCursor';
import SmoothScroll from './js/utils/SmoothScroll';
import { Provider } from 'react-redux';
import store from './js/redux/store';
import NotFound from './js/layout/NotFound';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <Provider store={store}>
  <SmoothScroll>
  <CustomCursor />
  <BrowserRouter>
    <Routes>
        <Route path='/' element={<App />}/>     
        <Route path='/cienciaconecta' element={<ProjectPage video={require("./assets/videos/cienciaconecta.webm")} data={require("./assets/proyectos/cienciaconecta.json")}/>}/>     
        <Route path='/pap-detect' element={<ProjectPage video={require("./assets/videos/pap.webm")} data={require("./assets/proyectos/pap.json")}/>}/>     
        <Route path='/ezequiel-torres' element={<ProjectPage video={require("./assets/videos/ezetorres.webm")} data={require("./assets/proyectos/ezetorres.json")}/>}/>     
        <Route path='/natours' element={<ProjectPage video={require("./assets/videos/natours.webm")} data={require("./assets/proyectos/natours.json")}/>}/>     
        <Route path='/information-retrieval' element={<ProjectPage video={require("./assets/videos/informationret.webm")} data={require("./assets/proyectos/informationret.json")}/>}/>     
        <Route path="*" element={<NotFound />}/>
    </Routes>
  </BrowserRouter>
  </SmoothScroll>
  </Provider>
);