import Mate from "../../assets/Mate"
import Sierra from "../../assets/Sierra"
import Sol from "../../assets/Sol"

import gsap from "gsap";
import { useRef } from "react";
import {useGSAP} from "@gsap/react"

const Dibujos = () => {
    const sierraRef = useRef()
    const mateRef = useRef()
    const solRef = useRef()

    useGSAP(() => {
        sierraRef.current.childNodes.forEach((path) => {
            const length = path.getTotalLength();
            path.style.strokeDasharray = length;
            path.style.strokeDashoffset = length;

            gsap.fromTo(
                path,
                { strokeDashoffset: length, opacity: 0},
                { strokeDashoffset: 0,
                  opacity: 1,
                  duration: 1,
                  scrollTrigger: {
                    trigger: path,
                    start: "top bottom-=100",
                    end: "bottom 10%",
                    scrub: true,
                  },
                }
              );
        })

        mateRef.current.childNodes.forEach((path) => {
            const length = path.getTotalLength();
            path.style.strokeDasharray = length;
            path.style.strokeDashoffset = length;
            gsap.fromTo(
                path,
                { strokeDashoffset: length, opacity: 0},
                {strokeDashoffset: 0,
                  opacity: 1,
                  duration: 1,
                  scrollTrigger: {
                    trigger: path,
                    start: "top bottom-=100",
                    end: "bottom 40%",
                    scrub: true,
                  },
                }
              );
        })

        solRef.current.childNodes.forEach((path) => {
            const length = path.getTotalLength();
            path.style.strokeDasharray = length;
            path.style.strokeDashoffset = length;
            gsap.fromTo(
                path,
                { strokeDashoffset: length, opacity: 0},
                { strokeDashoffset: 0,
                  opacity: 1,
                  duration: 1,
                  scrollTrigger: {
                    trigger: path,
                    start: "top bottom-=100",
                    end: "bottom 40%",
                    scrub: 3,
                    ease: "power1.inOut",
                },
              }
          );
        })
      });

    return (
        <section className="dibujos elemento-horizontal">
                <div className="dibujos__vector dibujos__vector--sierra"><Sierra ref={sierraRef}/></div>
                <div className="dibujos__vector dibujos__vector--mate"><Mate ref={mateRef}/> </div>
                <div className="dibujos__vector dibujos__vector--sol"><Sol ref={solRef}/></div>
        </section>
    )
}

export default Dibujos;