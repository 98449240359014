

const Backarrow = (props) => {

    return (
        <svg width="52" height="32" viewBox="0 0 52 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 15.5L0.292893 14.7929C-0.0976311 15.1834 -0.0976311 15.8166 0.292893 16.2071L1 15.5ZM14.7929 30.7071L15.5 31.4142L16.9142 30L16.2071 29.2929L14.7929 30.7071ZM14.7929 0.292893L0.292893 14.7929L1.70711 16.2071L16.2071 1.70711L14.7929 0.292893ZM0.292893 16.2071L14.7929 30.7071L16.2071 29.2929L1.70711 14.7929L0.292893 16.2071Z" fill="#E5E5E0"/>
            <path d="M50.9985 16.75H51.9985V14.75H50.9985V16.75ZM0.998535 16.75H50.9985V14.75H0.998535V16.75Z" fill="#E5E5E0"/>
        </svg>
    )
    
}

export default Backarrow;