import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import { RedirectPage } from "../utils/utils";


const NotFound = () => {
    const pathAnimar = useRef();

    useGSAP(() => {
        gsap.to(pathAnimar.current, {
            attr: {d: pathAnimar.current.getAttribute("finalpath")},
            duration: 5,
            ease: "power1.inOut",

        })
    })

    return (
        <article className="not-found">
            <div className="not-found__text-container">
                <h1 className="not-found__title">Error 404</h1>
                <p className="not-found__text">Unfortunately the page you are looking for is not available.</p>
                <p className="not-found__text">Meanwhile you can contact me through my social media.</p>

                <div className="not-found__social">
                    <p className="not-found__link hoverable" onClick={() => RedirectPage("https://www.linkedin.com/in/tomas-silva-fiezzi-62aa181a4/")}>LinkedIn</p>
                    <p className="not-found__link hoverable" onClick={() => RedirectPage("mailto:tomas.sfiezzi@gmail.com")}>Email</p>
                    <p className="not-found__link hoverable" onClick={() => RedirectPage("https://github.com/tsilva9")}>Github</p>
                </div>
            </div>


            <svg viewBox={`0 0 1440 160`} fill="none" xmlns="http://www.w3.org/2000/svg" className="not-found__shape">
                <path 
                    ref={pathAnimar}
                    d="M703.856 0C407.81 0 106.63 48.529 0 71.8216V206H1440V83.5739C995.902 71.2649 1073.92 0 703.856 0Z"
                    finalpath="M711 149.927C414.953 149.927 270 54.0037 0 81.9228V206H1440V0C985.5 42.46 1081.06 149.927 711 149.927Z"
                    fill="#E5E5E0"
                />
            </svg>

            
        </article>
    )
}


export default NotFound;

