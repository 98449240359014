import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loadedImages: 0,
  };

const resourcesSlice = createSlice({
    name: 'resources',
    initialState: initialState,
    reducers: {
        imageLoaded(state, action) {
            state.loadedImages = state.loadedImages + 1;
        },
    }
})

export const resourcesActions = resourcesSlice.actions

export default resourcesSlice