import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resourcesActions } from '../redux/resources-slice';


const ProjectThumb = (props) => {
    const navigate = useNavigate();
    const thumb = useRef(null)
    const imagenRef = useRef(null)
    const textContainer = useRef(null)

    const dispatch = useDispatch();

    const [mouse, setMouse] = useState({
        mouseX: null,
        mouseY: null
    })
    
    const lowercaseAndHyphenateString = (str) => {
        return str.toLowerCase().replace(/\s+/g, '-');
    };

    const mouseMove = (e) => {
        setMouse({
            mouseX: ((e.clientX - thumb.current.offsetWidth) / (thumb.current.offsetWidth / 2)) * 10,
            mouseY: ((e.clientY - thumb.current.offsetHeight) / (thumb.current.offsetHeight / 2)) * 10
        })
    }

    const mouseLeave = () => {
        setMouse({
            mouseX: null,
            mouseY: null
        })
    }

    useEffect(() => {
        textContainer.current.style.setProperty("--translateX", mouse.mouseX + "px")
        textContainer.current.style.setProperty("--translateY", mouse.mouseY + "px")
    }, [mouse])

    useEffect(() => {
        
        const handleImageLoad = () => {
            dispatch(resourcesActions.imageLoaded());
          };

        imagenRef.current.addEventListener('load', handleImageLoad)

        return () => {
            if (imagenRef.current) {
                imagenRef.current.removeEventListener('load', handleImageLoad)
            }
          };

    }, [dispatch])



    return (
        <div className="project-thumb hoverable" onClick={() => {navigate(`./${lowercaseAndHyphenateString(props.nombre)}`)}} id={props.id} 
        onMouseMove={(e) => mouseMove(e)}
        onMouseLeave={(e) => mouseLeave()}
        ref={thumb}
        >
            <div className="project-thumb__animation-container">
                <div className="project-thumb__text-container" ref={textContainer}>    
                    <h6 className="project-thumb__title">{props.nombre}</h6>
                    <p className="project-thumb__see-more">see more {">"}</p>
                </div>
            </div>
            
            <img className="project-thumb__image" src={props.img} alt="" ref={imagenRef}/>
        </div>
    )
}

export default ProjectThumb;