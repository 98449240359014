import { useEffect, useRef} from "react";

import AboutMe from "../layout/AboutMe";
import Dibujos from "../layout/Dibujos";
import Hero from "../layout/Hero";
import Contact from "../layout/Contact";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Works from "../layout/Works";
import ProjectGallery from "../layout/ProjectGallery";
import {useGSAP} from "@gsap/react"

import * as emailjs from "@emailjs/browser"

import useScreenWidth from "../utils/useScreenWidth";

gsap.registerPlugin(ScrollTrigger);

const Main = () => {
  const component = useRef(null);
  const horizontal = useRef(null);
  let isSmallScreen = useScreenWidth(600);
  
  let containerAnimationRef = useRef(null)

  useGSAP(() => {
      if (!isSmallScreen) {

      let panels = gsap.utils.toArray(".elemento-horizontal");
      let width = horizontal.current.offsetWidth - component.current.offsetWidth;

      containerAnimationRef.current = gsap.to(panels, {
        x: -width,
        ease: "none",
        scrollTrigger: {
          trigger: horizontal.current,
          pin: true,
          scrub: 1,
          // snap: 1,
          end: () => "+=" + width
        }
      });
    }
    }, 
    
    // {dependencies: [isLoading]}
    );

    useEffect(() => {
      emailjs.init({
        publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
        limitRate: {
          // Set the limit rate for the application
          id: 'app',
          // Allow 1 request per 10s
          throttle: 10000,
        },
      })
    }, [])

  return (    
      <div className="app" ref={component}>
        <Hero/>

        <div className="horizontal-section" ref={horizontal}>
            <AboutMe/>
            <Dibujos />
            <Works/>
            <ProjectGallery containerAnimation={containerAnimationRef}/> 
        </div>
        <Contact containerAnimation={containerAnimationRef}/>
      </div>
  );
}

export default Main;
