import { useNavigate } from "react-router-dom";
import Backarrow from "../../assets/Backarrow"
import { RedirectPage } from "../utils/utils";

const ProjectHeader = (props) => {
    const navigate = useNavigate();

    return (
        <div className="project-header">
            <div className="project-header__arrow-container hoverable" onClick={() => navigate(-1)}>
                <Backarrow />
            </div>

            <div className="project-header__text-container hoverable" onClick={() => RedirectPage(props.link)}>
                <h2 className="project-header__text project-header__text--title">{props.title}</h2>
                <p className="project-header__text project-header__text--see-more ">see more</p>
            </div>
            
            <video className="project-header__video" autoPlay muted loop onLoadedData={props.handleVideoLoad()}>
                <source src={props.video} type="video/webm"/>
            </video>
        </div>
    )
}

export default ProjectHeader;