import { useState, useEffect } from 'react';

const useScreenWidth = (width) => {
  const [mobile, setMobile] = useState(window.innerWidth < width);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < width);
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  return mobile;
}

export default useScreenWidth;