import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useRef } from "react";

const LoadingScreen = (props) => {
    const pathAnimar = useRef();

    useGSAP(() => {
        gsap.to(pathAnimar.current, {
            attr: {d: pathAnimar.current.getAttribute("finalpath")},
            duration: 5,
            ease: "sine.inOut",
            repeat: -1,
            yoyo: true
        })
    })
    return (
        <div className="loading-screen">
            <div className="loading-screen__container">
                <svg className="loading-screen__content" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path 
                        className="loading-screen__path"
                        ref={pathAnimar}
                        d="M369.563 34.8417C519.642 34.8417 593.125 94.4443 730 77.0965V0L6.10352e-05 0V128C230.406 101.617 181.963 34.8417 369.563 34.8417Z"
                        finalpath="M373.5 48.5003C532.5 75.4999 593.125 145.348 730 128V-1.52588e-05L0 -1.52588e-05V59C230.406 32.6171 268 30.5855 373.5 48.5003Z"
                        fill="#959595"/>
                </svg>

                <h1 className="loading-screen__text loading-screen__text--name">Tomas Silva Fiezzi</h1>
                <h4 className="loading-screen__text loading-screen__text--folio">© Portfolio 2024</h4>
                
            </div>
            
            

        </div>
    )
}

export default LoadingScreen;
